import React, { useState } from 'react'
import { Rating } from '@material-ui/core'
import { Star } from '@material-ui/icons'
import _ from 'lodash';
import HippaIconSection from '@Components/Common/HippaIconSection';
import Slider from "react-slick";
import VedioPlayer from '@Components/Common/VedioPlayer';
import { useRouter } from 'next/router';

const settings_3 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    lazyLoad: 'progressive',
    speed: 5000,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1020,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 360,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        }
    ],
};
function HomeSectionFive({ urlChange, setUrlchange, hideContent }) {

    const CONTENT_LIST = [
        { text: `Greatest gift to my body`, color: "prplbx", userName: "Dan L." },
        { text: `I just feel whole.`, color: "orange-bx", userName: "Marianne H." },
        { text: `This s**t works!`, color: "gree-bx", userName: "Ryan L." },
    ];
    const router = useRouter();
    return (
        <div className='home_fifth_section right-img-wrap'>
            <div className='container'>
                {(!hideContent) &&
                    <>
                        <h2>BIOHACKING SIMPLIFIED</h2>
                        <div className='video_secFive'>
                            {/*  <VedioPlayer url={urlChange ? urlChange : "https://www.youtube.com/@snipnutrition"} />*/}
                            <iframe width="1020" height="315" src="https://www.youtube.com/embed/jbWNRQCKVlQ?si=YQb8VChVqZuFS0Ow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            {/*  <a href="https://www.youtube.com/@snipnutrition" target="_blank"><img src="/images/new-image.png" />  </a>*/}

                        </div>
                    </>
                }
                {(!hideContent) &&
                    <div className='review_section'>
                        <div className='d-flex rating_section_content'>
                            {/* <Slider {...settings_3}> */}
                            {_.map(CONTENT_LIST, ({ text, color, userName }, index) => (
                                <div className='' key={index}>
                                    <div className={`contain-bx ${color}`}>
                                        <p>“{text}”</p>
                                        <Rating
                                            name="rating"
                                            value={5}
                                            readOnly
                                            sx={{ color: "black" }}
                                            emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />
                                        <p>{userName}</p>
                                    </div>

                                </div>
                            ))}
                            {/* </Slider> */}
                        </div>
                    </div>
                }
                <div className='text_section'>
                    <div className='d-flex'>
                        <div>
                            <p>SNiP Nutrigenomics is committed to your privacy protection. </p>
                            <p>
                                We do not store or sell your DNA data to third parties, including employers, health insurance companies, or other entities.
                            </p>
                        </div>
                        <div>
                            <h2>Advanced Privacy Protection</h2>
                        </div>
                    </div>
                    <p>
                        Your DNA is collected solely for the purposes of generating your DNA
                        report(s) and for the DNA-informed customization of your personalized
                        nutritional supplement.
                    </p>
                </div>
            </div>
            <HippaIconSection />
            {router?.pathname === "/get_started" &&
                <div className='right-img'><img src="/images/molecular_right.png" /></div>
            }
        </div>
    )
}

HomeSectionFive.propTypes = {}

export default HomeSectionFive