import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { addToCartMethod, isPercentageAssign } from '@Components/Common/CommonMethods';
import _ from 'lodash';
import api from '@api/Apis';
import { GetStartedSecondStyle } from './GetStartedProductPage.style';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';

export async function _getProductInGetStarted(setQtyState) {
    const PARENT_REFFERAL = Cookie.get('refferal-code')?.length > 0 ? Cookie.get('refferal-code') : null;
    try {
        const PRODUCT_DATA = await api.getAllProduct({ refferalCode: PARENT_REFFERAL, slug: 'us' });
        const { status, data } = PRODUCT_DATA;
        if (status === 200) {
            const GET_STARTED_PRODUCT = _.find(data?.products, { id: "1" })
            setQtyState({ id: +GET_STARTED_PRODUCT?.id, qty: 1, maxQty: +GET_STARTED_PRODUCT?.quantity, minQty: 1 });
            return GET_STARTED_PRODUCT;
        }
        return null;
    } catch (e) {
        throw new Error('somthing went wrong with products api');
    }
}

const GetStartedSecond = ({ list, ...props }) => {
    const router = useRouter();
    const [qtyState, setQtyState] = useState({ id: null, qty: null, maxQty: null, minQty: 1 });
    const [product, setProduct] = useState({});
    const [cartNotify, setCartNotify] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        _getProductInGetStarted(setQtyState).then(res => {
            if (res) setProduct(res);
        });
    }, []);

    return (
        <GetStartedSecondStyle className='main_getStartedSecond_class'>
            <div className='getStartedSecond_first'>
                <div className='background_cc'><h2 className='color'>TOOL #1</h2></div>
                <div className='titleAndTextBox'>
                    <h2>DNA TEST KITS + HEALTH REPORTS</h2>
                    <h2>Information that’s actionable.</h2>
                    <p>Get 11 DNA reports containing more than insights.</p>
                </div>
                {router?.pathname !== "/get_started" &&
                    <div className='text_boxes'>
                        <div>
                            <p>
                                At SNiP, we go further
                                than facts. Your
                                reports contain
                                actionable information
                                that’s personalized
                                just for you.
                            </p>
                        </div>
                        <div>
                            <p>
                                Learn how your genes
                                influence your mind
                                and body, and
                                discover what you can
                                do to offset your
                                genetic vulnerabilities.
                            </p>
                        </div>
                        <div>
                            <p>
                                Containing clear,
                                easy-to-follow
                                recommendations so
                                you can take action
                                now.
                            </p>
                        </div>
                    </div>
                }
            </div>
            <div className='getStartedSecond_second'>
                <div className='first'>
                    <div className='image_text_one'>
                        <div className='image'>
                            {product?.product_images?.length > 0 &&
                                <>
                                    <img className='bonus-image' src="/images/bonus.png" />
                                    <img src={`${process.env.API_URL}/${product?.product_images?.[0]?.image}`} />
                                </>
                            }
                        </div>
                        <div>
                            {product?.cost_price && <div className='price'>${parseFloat(product?.cost_price).toFixed(2)}</div>}
                            {/* <p style={{
                                marginBottom: 0,
                                fontWeight: 600,
                                color: "#e553b3"
                            }}>Introductory price</p> */}
                        </div>
                    </div>
                    <div className='include_section'>
                        <h3>INCLUDES:</h3>
                        {router?.pathname === "/landing_page" ?
                            <p>
                                DNA test, lab fee, return
                                shipping to lab and digital
                                delivery of reports
                            </p>
                            :
                            <p>
                                DNA test kit, lab analysis,
                                shipping + 11 electronically generated personalized DNA health reports
                            </p>
                        }

                        {/* <span>*Free delivery and return shipping to lab</span> */}
                    </div>
                    <div className='add_to_cart_getStarted'>
                        <button onClick={() => {
                            addToCartMethod({
                                id: product?.id,
                                quantity: qtyState?.qty,
                                is_autoship: false
                            },
                                props?.setshowminicart,
                                setCartNotify,
                                props?.cartdata?.products,
                                props?.setCartData,
                                false,
                                setErrorMsg,
                            )
                        }}>Add to Cart</button>
                    </div>
                    {errorMsg && <span className='error' style={{ color: "red" }}>{errorMsg}</span>}
                </div>
                <div className='second'>
                    <div className='content_one'>
                        <p>
                            Easy-to-understand results, epigenetic
                            lifestyle tips and suggested labs for
                            health tracking, plus peer-reviewed
                            science and live links to additional
                            resources.
                        </p>
                        <p>
                            Premium CODE Report unlocks
                            your supplement’s unique formulation,
                            based on your DNA.
                        </p>
                    </div>
                    <div className='content_two'>
                        <h3>Premium CODE Report<br />
                            + 10 Key Health Reports:</h3>
                        <ul>
                            {_.map(list, (row, index) => (
                                <li key={index + 1}>{row}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {router?.pathname === "/get_started" &&
                <div className='right-img'><img src="/images/molecular_right.png" /></div>
            }
        </GetStartedSecondStyle>
    )
}

export default GetStartedSecond;