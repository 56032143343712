import React, { useEffect, useState } from 'react'
import { GetStartedFirstStyle } from './GetStartedProductPage.style';

const GetStartedFirst = ({ myRef }) => {
    const [state, setState] = useState(0);
    const [fadeProp, setFadeProp] = useState({ fade: "fade-in" })
    const TEXTS = [
        "advanced",
        "innovative",
        "cutting-edge",
        "powerful",
        "synergistic",
        "simple",

    ];
    useEffect(() => {
        const interval = setInterval(() => {
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                setState(index => index + 1)
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, 1500);
        return () => clearInterval(interval);
    }, [fadeProp]);
    return (
        <GetStartedFirstStyle>
            <div className='firstSection_getStarted'>
                <div className='twoHeading'>
                    <h2><span className='color'>TWO TOOLS</span>.</h2>
                    <p className='underline'></p>
                    <h2>ONE SIMPLE <br />SOLUTION.</h2>
                </div>
                <div className='contents'>
                    <p>Actionable insights plus personalized nutrition.</p>
                    <p>The world’s most <strong className={fadeProp.fade}> {TEXTS[state % TEXTS.length]}</strong>  wellness system. </p>

                    <p>Built just for you.</p>
                    <p> DNA insights unlock the code to your nutritional needs.</p>
                </div>

            </div>
        </GetStartedFirstStyle>
    )
}

export default GetStartedFirst;